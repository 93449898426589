import { StatusBar } from "expo-status-bar";
import React, { useEffect, useRef, useState } from "react";
import { Platform } from "react-native";
import UserInfoContext from './components/UserInfoContext';
import Navigation from './navigation';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import useCachedResources from './hooks/useCachedResources';
import { useFonts } from 'expo-font';
import { useColorScheme } from './hooks/useColorScheme';
// Navigators: need one for main site and one for drawer and one for botoms tabs.
// and one for login/no-auth stuff.
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';

// Screens for app.
import axios from 'axios';
import { Subscription } from 'expo-media-library';
import * as Notifications from 'expo-notifications';
import { LoadingSpinnerComponent } from './components/LoadingSpinner';
import { SpaceMono_400Regular } from '@expo-google-fonts/space-mono';
import { GestureHandlerRootView } from "react-native-gesture-handler";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

export default function App({ navigation }) {

  const colorScheme = useColorScheme();
  const [loaded] = useFonts({
    SpaceMono: require('./assets/fonts/SpaceMono-Regular.ttf'),
  });
  const [userInfo, setUserInfo] = useState<any>({});
  const isLoading = useCachedResources();

  // NOTIFICATIONS
  const [notification, setNotification] = useState<any>();
  const notificationListener = useRef<Subscription | undefined>();
  const responseListener = useRef<Subscription | undefined>();

  //axios.defaults.baseURL = 'https://localhost:7036/';

   axios.defaults.baseURL = 'https://api.lifeoar.io/';


  axios.defaults.timeout = 30000;
  axios.defaults.withCredentials = true;

  useEffect(() => {
    if (Platform.OS == "ios") {
      notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
        setNotification(notification);
      });

      responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
        console.log(response);
      });

      return () => {
        Notifications.removeNotificationSubscription(notificationListener.current);
        Notifications.removeNotificationSubscription(responseListener.current);
      };
    }
  }, []);

  if (isLoading) {
    return (
      null
    );
  } else {
    return (
      <SafeAreaProvider>
        <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
          <Navigation colorScheme={"dark"} navigation={navigation}></Navigation>
          <StatusBar style="auto" />
        </UserInfoContext.Provider>
      </SafeAreaProvider>
    );
  }
} 
