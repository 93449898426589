import axios from "axios";
import { LifeOarEvent } from "../../types/oarTypes";

export const saveNewEvent = (newEvent : LifeOarEvent) => {
	return axios
		.post(`events`, newEvent)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully saved Event data.');
		});
};

export const getAgendaItemsForMonth = (year: number, month :number) => {
	return axios
		.get(`events/${year}/${month}`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully saved Event data.');
		});
};

export const updateEventAttendance = (groupId: string, eventId: string, userId: string, stateToToggle: string) => {
	return axios
		.put(`groups/${groupId}/events/${eventId}/${userId}/${stateToToggle}`)
		.then(function (response) {
			return (response.data);
		})
		.catch(function (error) {
			console.log(error.message);
		})
		.finally(function () {
			console.log('Successfully saved Event data.');
		});
};
