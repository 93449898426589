import React, { useEffect, useRef, useState } from "react";
import { seaBlueColor } from "../../constants/Colors";
import moment from "moment";
import { Agenda, AgendaEntry, AgendaSchedule, Calendar, DateData } from 'react-native-calendars';
import { View, Text, TextInput, Switch, TouchableOpacity, Pressable } from "react-native";
import { globalStyles } from "../../constants/GlobalStyles";
import DateTimePicker from '@react-native-community/datetimepicker';
import * as EventService from "../../services/Events/eventService";
import * as GroupService from "../../services/Groups/groupsService";
import { useIsFocused } from "@react-navigation/native";
import { LoadingSpinnerComponent } from "../LoadingSpinner";
import { FontAwesome5, FontAwesome6 } from "@expo/vector-icons";
import UserInfoContext from "../UserInfoContext";

export default function Schedule({ route, navigation, minDate, maxDate, scheduleType }) {
  const [isLoading, setIsLoading] = useState(true);
  const [time, setTime] = useState(new Date());
  const [showPicker, setShowPicker] = useState(false);
  const [isAM, setIsAM] = useState(true);
  const todaysDate = moment().format('YYYY-MM-DD')
  const vacation = { key: 'vacation', color: 'red', selectedDotColor: 'blue' };
  const massage = { key: 'massage', color: 'blue', selectedDotColor: 'blue' };
  const workout = { key: 'workout', color: 'green' };
  const [selectedDate, setSelectedDate] = useState(null)
  const { userInfo } = React.useContext(UserInfoContext);
  const [agendaItems, setAgendaItems] = useState({})
  const [currentMonth, setCurrentMonth] = useState(null)
  const isFocused = useIsFocused();

  React.useEffect(() => {
    var today = new Date();
    var dateObj: DateData = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate(),
      timestamp: moment(today).valueOf(),
      dateString: moment(today).format("YYYY-MM-DD")
    }

    setAgendaItems({})
    if (scheduleType == 0) {
      loadPersonalAgendaData(dateObj)
    }
    else {
      loadGroupAgendaData(dateObj, route.params.groupId)
    }
    setCurrentMonth(new Date().toLocaleString('default', { month: 'long' }))
  }, [route, navigation, isFocused]);

  const loadItems = (dateData: DateData) => {
    setAgendaItems({})
    console.log("Loading data for month number " + dateData.month)
    setIsLoading(true)
    if (scheduleType == 0) {
      loadPersonalAgendaData(dateData)
    }
    else {
      loadGroupAgendaData(dateData, route.params.groupId)
    }
    setIsLoading(false)
  };

  const loadGroupAgendaData = (dateData: DateData, groupId: string) => {

    GroupService.getGroupScheduleForMonth(dateData.year, dateData.month, groupId).then((results: []) => {
      const items = agendaItems || {};
      const monthSelected = moment(`${dateData.year}-${dateData.month}`, 'YYYY-MM');
      const daysInMonth = new Date(dateData.year, dateData.month, 0).getDate();
      for (let day = 1; day <= daysInMonth; day++) {
        const dateString = `${dateData.year}-${String(dateData.month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        items[dateString] = [];
      }

      var mappedResults = results.map((value: any, index, all) => {
        return {
          "name": value,
          "day": moment.utc(value.startDate).format('YYYY-MM-DD')
        }
      })

      mappedResults.forEach(element => {
        if (!items[element.day]) {
          items[element.day] = [];
        }
        items[element.day].push({
          name: element.name,
          day: element.day
        });
      });
 
      setAgendaItems(items);
    })
    setIsLoading(false);
  };

  const loadPersonalAgendaData = (dateData: DateData) => {
    EventService.getAgendaItemsForMonth(dateData.year, dateData.month).then((results: []) => {
      const items = agendaItems || {};
      const monthSelected = moment(`${dateData.year}-${dateData.month}`, 'YYYY-MM');
      const daysInMonth = new Date(dateData.year, dateData.month, 0).getDate();
      for (let day = 1; day <= daysInMonth; day++) {
        const dateString = `${dateData.year}-${String(dateData.month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        items[dateString] = [];
      }

      var mappedResults = results.map((value: any, index, all) => {
        return {
          "name": value,
          "day": moment.utc(value.startDate).format('YYYY-MM-DD'),
        }
      })

      mappedResults.forEach(element => {
        if (!items[element.day]) {
          items[element.day] = [];
        }
        items[element.day].push({
          name: element.name,
          day: element.day
        });
      });
      console.log(items)
      setAgendaItems(items);
    })
    setIsLoading(false);
  };

  const onKnobPress = () => {
    console.log('knob press registered')
  }

  const rowHasChanged = (r1: AgendaEntry, r2: AgendaEntry) => {
    return r1.name !== r2.name;
  };

  const renderKnob = () => {
    return <View>
      <TouchableOpacity onPress={onKnobPress}>
        <Text>This is the knob!</Text>
      </TouchableOpacity>
    </View>;
  }

  const onCalendarToggled = (calendarOpened) => {
    console.log(calendarOpened);
  }

  const onImGoingClicked = (agendaItem: any) => {
    if (agendaItem.name.going.includes(userInfo.objectId)) {
      alert("You are already going to this event")
      return
    }
    else {
      EventService.updateEventAttendance(route.params.groupId, agendaItem.name._id, userInfo.objectId, "going").then((results: []) => {
        loadItems({ year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate(), timestamp: 0, dateString: "YYYY-MM-DD" })
      })
    }
  }

  const onIMightGoClicked = (agendaItem: any) => {
    if (agendaItem.name.maybeGoing.includes(userInfo.objectId)) {
      alert("You have already indicated you might go to this event")
      return
    }
    else {
      EventService.updateEventAttendance(route.params.groupId, agendaItem.name._id, userInfo.objectId, "maybe").then((results: []) => {
        loadItems({ year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate(), timestamp: 0, dateString: "YYYY-MM-DD" })
      })
    }
  }

  const onICantGoClicked = (agendaItem: any) => {
    if (agendaItem.name.declined.includes(userInfo.objectId)) {
      alert("You have already indicated you can't go to this event")
      return
    }
    else {
      EventService.updateEventAttendance(route.params.groupId, agendaItem.name._id, userInfo.objectId, "declined").then((results: []) => {
        loadItems({ year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate(), timestamp: 0, dateString: "YYYY-MM-DD" })
      })
    }
  }

  const renderEmptyDate = () => {
    return (
      <View style={globalStyles.agendaEmptyDate}>
        <Text style={globalStyles.agendaItemText}>No events</Text>
      </View>
    );
  };

  const onDayPress = (month: DateData) => {
    loadItems(month);
    setCurrentMonth(new Date(month.year, month.month - 1).toLocaleString('default', { month: 'long' }))
  }

  const renderItem = (agendaItem: any, isFirst: boolean) => {
    const localStartDateTime = moment.utc(agendaItem.name.startDateTime).local();
    const localStartDate = moment.utc(agendaItem.name.startDate).toISOString();

    return (
      <View style={[globalStyles.agendaItem, { flexDirection: "row", width: "100%", height: scheduleType == 0 ? 75 : 75 }]}>
        <TouchableOpacity onPress={() => alert(agendaItem.name.title)}>
          <Text style={globalStyles.agendaItemTitle}>{agendaItem.name.title}</Text>
          <Text style={globalStyles.agendaItemText}>Length: {agendaItem.name.lengthInMins} minutes</Text>
          <Text style={globalStyles.agendaItemText}>{localStartDateTime.toDate().toLocaleTimeString()} {localStartDateTime.toDate().toLocaleTimeString('en-us', { timeZoneName: "short" }).split(' ')[2]} </Text>
          {scheduleType != 0 &&
            <Text style={globalStyles.agendaItemText}>Going: {agendaItem.name.going.length} Maybe: {agendaItem.name.maybeGoing.length}  Declined: {agendaItem.name.declined.length}</Text>
          }
        </TouchableOpacity>
        {scheduleType != 0 &&
          <View style={[, { flexDirection: "row", alignItems: "center" }]} >
            <Pressable onPress={() => onImGoingClicked(agendaItem)} >
              <FontAwesome6 name="check-circle" size={20} color={agendaItem.name.going.includes(userInfo.objectId) ? seaBlueColor : "black"} style={{ marginLeft: 15 }} />
            </Pressable>
            <Pressable onPress={() => onIMightGoClicked(agendaItem)}>
              <FontAwesome6 name="question-circle" size={20} color={agendaItem.name.maybeGoing.includes(userInfo.objectId) ? seaBlueColor : "black"} style={{ marginLeft: 10 }} />
            </Pressable>
            <Pressable onPress={() => onICantGoClicked(agendaItem)}>
              <FontAwesome6 name="ban" size={20} color={agendaItem.name.declined.includes(userInfo.objectId) ? seaBlueColor : "black"} style={{ marginLeft: 10 }} />
            </Pressable>
          </View>
        }
      </View>
    );
  };

  if (isLoading) {
    return (
      <LoadingSpinnerComponent />
    );
  } else {
    return (
      <View style={globalStyles.container}>
        <View style={{ height: 35, width: "95%", justifyContent: "center", alignContent: "center", alignItems: "center", alignSelf: "center", flexWrap: "nowrap", backgroundColor: seaBlueColor }}>
          <Text style={{ color: "white", fontWeight: "bold" }}> Month: {currentMonth}</Text>
        </View>
        <View style={globalStyles.calendarContainer}>
          <Agenda onCalendarToggled={onCalendarToggled} items={agendaItems} renderItem={renderItem} renderEmptyDate={renderEmptyDate}
            hideKnob={false} showClosingKnob={true} rowHasChanged={rowHasChanged} onDayPress={(dayData) => onDayPress(dayData)} 
            theme={{ dayTextColor: seaBlueColor, selectedDayBackgroundColor: seaBlueColor, selectedDayTextColor: "white", agendaKnobColor: seaBlueColor, arrowColor: seaBlueColor }}
            style={{ alignContent: "center" }}
            selected={new Date().toDateString()}></Agenda>
            
        </View>
      </View>
    );
  }
}


