
import { FontAwesome5 } from "@expo/vector-icons";
import React from "react";
import { Pressable, ScrollView, TouchableOpacity, View } from "react-native";
import { globalStyles } from "../../constants/GlobalStyles";
import { JournalEntryTypesDict, JournalSettings, UserSettings } from "../../types/oarTypes";
import { Text } from "react-native";
import { Switch } from "@rneui/themed";
import { seaBlueColor } from "../../constants/Colors";
import UserInfoContext from "../../components/UserInfoContext";
import { updateEnabledJournalTypes, getEnabledJournalTypes } from "../../services/Journal/journalService";
import { LoadingSpinnerComponent } from "../../components/LoadingSpinner";



export default function JournalSettingsScreen({ navigation, route }) {
  const { userInfo } = React.useContext(UserInfoContext);
  const [enabledStates, setEnabledStates] = React.useState<JournalSettings[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const enabledTypes: JournalSettings[] = [];

    getEnabledJournalTypes().then((results) => {

      if (results != undefined && results) {


        // Iterate over the results and add each item to the enabledTypes array
        results.forEach((item) => {
          enabledTypes.push(item);
        });
        setEnabledStates(enabledTypes);
        setIsLoading(false);

      }
    })

  }, [navigation, route]);

  const toggleSwitch = (type) => {
    setEnabledStates((prevState) => {
      const newState = [...prevState];
      const index = newState.findIndex((item) => item._id === Number.parseInt(type));
      if (index !== -1) {
        newState[index] = {
          ...newState[index],
          enabled: !newState[index].enabled,
        };
      }
      else {
        newState.push({ _id: Number.parseInt(type), enabled: true });
      }
      return newState;
    });
  };

  const handleSubmit = () => {
    const payload = {
      objectId: userInfo.objectId,
      journalSettings: enabledStates,
    };

    updateEnabledJournalTypes(payload);
    navigation.navigate('Journal');
  }

  if (isLoading) {
    return (
      <LoadingSpinnerComponent />
    );
  } else {
    return (
      <>
        
          <ScrollView >
          <View style={{ ...globalStyles.centeredContainer, alignSelf:"center" }}>
            {Object.keys(JournalEntryTypesDict.types).map((type) => (
              <View key={type} style={{ ...globalStyles.centeredContainer, width: "90%", height: '90%', flexDirection: 'row', justifyContent: "space-between" }}>
                <Text style={{ ...globalStyles.listItemText, fontWeight: "bold", width: '75%', flex: 1, height: '100%', paddingBottom: 10 }}>{JournalEntryTypesDict.types[type].name}:</Text>
                <Switch color={seaBlueColor} value={
                  enabledStates.find((item) => item._id === Number.parseInt(type))?.enabled || false
                } style={{ transform: [{ scaleX: 1.0 }, { scaleY: 1.0 },] }}
                  onValueChange={() => toggleSwitch(type)} />
              </View>
            ))}
             </View>
          </ScrollView>
       

        <View style={{ ...globalStyles.centeredContainer, height: "100%", marginTop: 25, justifyContent: 'center' }}>
          <Pressable style={globalStyles.saveBtn} onPress={handleSubmit}>
            <Text style={globalStyles.loginText}>Update</Text>
          </Pressable>
        </View>
        <View style={{ height: 75 }}></View>
      </>
    );
  }
}
