import isEmpty from 'lodash/isEmpty';


const today = new Date().toISOString().split('T')[0];
const fastDate = getPastDate(3);
const futureDates = getFutureDates(12);
const dates = [fastDate, today].concat(futureDates);

function getFutureDates(numberOfDays: number) {
  const array: string[] = [];
  for (let index = 1; index <= numberOfDays; index++) {
    let d = Date.now();
    if (index > 8) {
      // set dates on the next month
      const newMonth = new Date(d).getMonth() + 1;
      d = new Date(d).setMonth(newMonth);
    }
    const date = new Date(d + 864e5 * index); // 864e5 == 86400000 == 24*60*60*1000
    const dateString = date.toISOString().split('T')[0];
    array.push(dateString);
  }
  return array;
}
function getPastDate(numberOfDays: number) {
  return new Date(Date.now() - 864e5 * numberOfDays).toISOString().split('T')[0];
}

export const existingJournalEntriesMock = [

  {
    title: "2024-10-05",
    data: [
      {
        journalEntryType: 8,
        value: "true",
      },
      {
        journalEntryType: 15,
        value: "true",
      },
      {
        journalEntryType: 20,
        value: "false",
      },
      {
        journalEntryType: 0,
        value: "4",
      },
      
    ],
  },
  {
    title: "2024-10-06",
    data: [
      {
        journalEntryType: 8,
        value: "false",
      },
      {
        journalEntryType: 15,
        value: "false",
      },
      {
        journalEntryType: 20,
        value: "false",
      },
      {
        journalEntryType: 0,
        value: "8",
      },
      {
        journalEntryType: 24,
        value: "6",
      }
    ],
  }
]

export const enabledJournalTypesMock = 
[
  {journalEntryType : 0, enabled: true},
  {journalEntryType : 1, enabled: true},
  {journalEntryType : 2, enabled: true},
  {journalEntryType : 3, enabled: true},
  {journalEntryType : 4, enabled: true},
  {journalEntryType : 5, enabled: true},
  {journalEntryType : 6, enabled: true},
  {journalEntryType : 7, enabled: true},
  {journalEntryType : 8, enabled: true}
]

export const journalItemsMock = [
  {
    title: dates[0],
    data: [{ journalEntryType: 1, value: true },
    { journalEntryType: 0, value: false }

    ]
  },
  {
    title: dates[1],
    data: [{ journalEntryType: 5, value: true },
    { journalEntryType: 3, value: false },
    { journalEntryType: 10, value: false },
    { journalEntryType: 20, value: false }
    ]
  }
];

