import React, { useCallback, useRef, useState } from 'react';
import { View, Button, StyleSheet, Text, TouchableOpacity, Pressable, Modal } from 'react-native';
import { Agenda, Calendar, CalendarProvider, ExpandableCalendar, DateData, WeekCalendar, AgendaList } from 'react-native-calendars';
import { globalStyles } from '../../constants/GlobalStyles';
import moment from 'moment';
import { seaBlueColor } from '../../constants/Colors';
import { useIsFocused } from '@react-navigation/native';
import { agendaItemsMock } from '../../mocks/agendaItems';
import { FontAwesome, FontAwesome5, FontAwesome6 } from '@expo/vector-icons';
import { getJournalTheme, lightThemeColor } from '../../components/journal/journalTheme';
import JournalItem from './JournalItem';
import { LoadingSpinnerComponent } from '../LoadingSpinner';
import { JournalEntryTypesDict } from '../../types/oarTypes';
import { fetchJournalTypes, getJournalEntriesForMonth } from '../../services/Journal/journalService';
import { existingJournalEntriesMock } from '../../mocks/journalItems';
import { Slider } from '@rneui/themed';


export default function Journal({ navigation, route }) {
  const [currentMonth, setCurrentMonth] = useState(new Date().toLocaleString('default', { month: 'long' }))
  const [agendaItems, setAgendaItems] = useState({});
  const isFocused = useIsFocused();
  const theme = useRef(getJournalTheme());
  const [isExpanded, setIsExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  // enabled types
  const [enabledJournalTypes, setEnabledJournalTypes] = useState([]);
  // existing entries on enabled types.
  const [journalEntries, setJournalEntries] = useState([]);
  const [existingJournalEntries, setExistingJournalEntries] = useState([]);
  const [journalEntryModalVisible, setJournalEntryModalVisible] = useState(false);
  const [journalEntryModalData, setJournalEntryModalData] = useState({ item: "", date: "" });
  const [journalEntryModalValue, setJournalEntryModalValue] = useState(0);


  React.useEffect(() => {

    const existingEntriesMock = existingJournalEntriesMock;
    const journalItems: any[] = [];
    const journalArray: any[] = [];
    var today = new Date();
    var dateObj: DateData = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate(),
      timestamp: moment(today).valueOf(),
      dateString: moment(today).format("YYYY-MM-DD")
    }
    setCurrentMonth(new Date().toLocaleString('default', { month: 'long' }))
    fetchJournalTypes().then((response) => {

      response.forEach((journalType) => {
        journalArray.push({ journalEntryType: journalType._id, value: "" });
      })
      setEnabledJournalTypes(response);
      const monthSelected = moment(`${dateObj.year}-${dateObj.month}`, 'YYYY-MM');
      var firstOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      const daysInMonth = firstOfNextMonth.getDate();
      for (let day = 1; day <= daysInMonth; day++) {
        const dateString = `${dateObj.year}-${String(dateObj.month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        journalItems.push({
          title: dateString,
          data: journalArray
        });
      }

      setJournalEntries(journalItems);

      getJournalEntriesForMonth(dateObj.year, dateObj.month).then((results) => {

        const updatedJournalEntries = journalItems.map(entry => {
          console.log("Journal Entry: " + JSON.stringify(entry));
          const existingEntry = existingEntriesMock.find(result => result.title === entry.title);
          if (existingEntry) {
            const updatedData = entry.data.map(journalEntry => {
              const matchingData = existingEntry.data.find(data => data.journalEntryType === journalEntry.journalEntryType);
              return matchingData ? { ...journalEntry, ...matchingData } : journalEntry;
            });
            return { ...entry, data: updatedData };
          }
          return entry;
        })
        setJournalEntries(updatedJournalEntries);
      })

      setIsLoading(false);
    });
  }, [route, navigation, isFocused]);

  const loadItems = (dateData: DateData) => {
    setIsLoading(true);
    const items = agendaItems || {};
    console.log("Loading data for month number " + dateData.month)
    const daysInMonth = new Date(dateData.year, dateData.month, 0).getDate();
    for (let day = 1; day <= daysInMonth; day++) {
      const dateString = `${dateData.year}-${String(dateData.month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
      items[dateString] = [];
    }
  };

  const itemPressed = (item, section) => {
    //navigation.navigate('JournalUpdate', { item: item.journalEntryType, section: section.title });

    setJournalEntryModalData({ item: item.journalEntryType, date: section.title });
    setJournalEntryModalVisible(true);
  }

  const Item = ({ item, section }) => (

    <View style={{ ...globalStyles.journalItemContainer }}>
      <Text style={globalStyles.journalItemTitle}>{JournalEntryTypesDict.types[item.journalEntryType].name}</Text>
      <Text style={globalStyles.journalItemText}>{item.value !== "" ? item.value : (
        <Pressable onPress={() => itemPressed(item, section)}>

          <Text style={{ ...globalStyles.journalItemText, color: "red" }}>Not logged</Text>
        </Pressable>
      )}
      </Text>

    </View>
  );

    const onJournalItemSubmit = () => {

    }

  const onDayPress = (month: DateData) => {
    loadItems(month);
    setCurrentMonth(new Date(month.year, month.month - 1).toLocaleString('default', { month: 'long' }))
  }

  const toggleCalendar = (event) => {
    setIsExpanded(!isExpanded);
  }

  if (isLoading) {
    return (
      <LoadingSpinnerComponent />
    );
  } else {
   

    return (
      <>
        <View style={{ ...globalStyles.container, alignSelf: "flex-start", alignContent: "center" }}>
          <View style={globalStyles.container}>
            <View style={{ ...globalStyles.container, height: "100%" }}>
              <CalendarProvider
                date={journalEntries[0]?.title}

                // onDateChanged={onDateChanged}
                // onMonthChange={onMonthChange}
                showTodayButton
                //todayButtonStyle={{ textColor: seaBlueColor }}
                // disabledOpacity={0.6}
                theme={{ textDayFontWeight: "bold" }}
              // todayBottomMargin={16}
              >
                <ExpandableCalendar
                  // testID={testIDs.expandableCalendar.CONTAINER}
                  // horizontal={false}

                  // disablePan
                  // hideKnob
                  firstDay={1}
                  initialPosition={ExpandableCalendar.positions.CLOSED}
                  theme={{ todayBackgroundColor: seaBlueColor, todayTextColor: 'white', selectedDayBackgroundColor: seaBlueColor }}
                // disableAllTouchEventsForDisabledDays
                //markedDates={marked.current}
                />
                <AgendaList
                  sections={journalEntries}
                  renderItem={({ item, section }) => <Item item={item} section={section} />}
                  // scrollToNextEvent
                  sectionStyle={globalStyles.journalSection}
                // dayFormat={'yyyy-MM-d'}
                />
              </CalendarProvider>
            </View>
          </View>
        </View>
        <View>
          <Modal animationType="slide" visible={journalEntryModalVisible} transparent={true} onRequestClose={() => setJournalEntryModalVisible(false)} style={globalStyles.modalContainer}>
            <View style={globalStyles.centeredView}>
              <View style={globalStyles.titleContainer}>
                <Text style={globalStyles.modalTitle}>Enter a value for {journalEntryModalData.item != "" ? JournalEntryTypesDict.types[journalEntryModalData.item].name : ""} for date {journalEntryModalData.date != "" ? journalEntryModalData.date : ""}</Text>
                <Pressable onPress={() => setJournalEntryModalVisible(false)}>
                  <FontAwesome name="close" color="black" size={22} />
                </Pressable>
              </View>
              <View style={globalStyles.modalView}>
                <View style={{ alignContent: "flex-start", flexDirection: "row", flexWrap: "wrap", alignItems: "center" }}>
                  <Slider minimumValue={0} maximumValue={10}
                    value={journalEntryModalValue}
                    onValueChange={setJournalEntryModalValue}
                    trackStyle={{ height: 10, backgroundColor: seaBlueColor }}
                    thumbStyle={{ height: 30, width: 30, backgroundColor: seaBlueColor }}
                    style={{ width: 200, height: 60 }}
                    step={1}
                    allowTouchTrack
                  ></Slider>
                  <Text style={{ paddingLeft: 20 }}>Value: {journalEntryModalValue}</Text>
                 
                 
                </View>
                <View style={{flexDirection:"column", flex:1, width:"25%"}}>
                    <Pressable style={globalStyles.saveBtn} onPress={onJournalItemSubmit}>
                      <Text style={globalStyles.loginText}>Submit</Text>
                    </Pressable>
                  </View>
              </View>
            </View>
          </Modal>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  calendar: {
    paddingLeft: 20,
    paddingRight: 20
  },
  header: {
    backgroundColor: 'lightgrey'
  },
  section: {
    backgroundColor: lightThemeColor,
    color: seaBlueColor,
    textTransform: 'uppercase',
    fontSize: 16,
  },
  item: {
    padding: 20,
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: seaBlueColor,
    flexDirection: 'row'
  },
  itemHourText: {
    color: 'black'
  },
  itemDurationText: {
    color: 'grey',
    fontSize: 12,
    marginTop: 4,
    marginLeft: 4
  },
  itemTitleText: {
    color: 'black',
    marginLeft: 16,
    fontWeight: 'bold',
    fontSize: 16
  },
  itemButtonContainer: {
    flex: 1,
    alignItems: 'flex-end'
  },
  emptyItem: {
    paddingLeft: 20,
    height: 52,
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: 'lightgrey'
  },
  emptyItemText: {
    color: 'lightgrey',
    fontSize: 14
  }
});