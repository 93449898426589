/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome5, FontAwesome6 } from '@expo/vector-icons'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { Image } from 'react-native';
import { ColorSchemeName, Pressable } from 'react-native';
import * as Colors from '../constants/Colors';
import { useColorScheme } from '../hooks/useColorScheme';
import ScheduleScreen from '../screens/ScheduleScreen';
import HomeScreen from '../screens/HomeScreen';
import TasksScreen from '../screens/Tasks/TasksScreen';
import AddTaskScreen from '../screens/Tasks/AddTaskScreen';
import EditTaskScreen from '../screens/Tasks/EditTaskScreen';
import GroupScreen from '../screens/Groups/GroupScreen';
import SplashScreen from '../screens/SplashScreen';
import AddGroupScreen from '../screens/Groups/AddGroupScreen';
import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../navigationTypes';
import LinkingConfiguration from './linkingConfiguration';
import * as GlobalDefaultHeader from '../components/header/globalDefaultHeader';
import LoginScreen from '../screens/LoginScreen';
import { createStackNavigator } from '@react-navigation/stack';
import App from '../App';
import ReactDOM from 'react-dom';
const Tab = createBottomTabNavigator();
const Stack = createNativeStackNavigator<RootStackParamList>();
const AuthStack = createNativeStackNavigator();
import { createRoot } from 'react-dom/client';
import GroupDetailScreen from '../screens/Groups/GroupDetailScreen';
import UserInfoContext from '../components/UserInfoContext';
import PublicScreen from '../screens/PublicScreen';
import EditGroupScreen from '../screens/Groups/EditGroupScreen';
import AddLocationScreen from '../screens/Locations/AddLocationScreen';
import { navigationRef } from '../services/Navigation/navigationService';
import AddEventScreen from '../screens/Events/AddEventScreen';
import JournalScreen from '../screens/JournalScreen';
import JournalSettingsScreen from '../screens/Journal/JournalSettingsScreen';
import JournalEntryScreen from '../screens/Journal/JournalEntryScreen';
import JournalUpdateScreen from '../screens/Journal/JournalUpdateScreen';

const Auth = () => {
  // Stack Navigator for Login and Sign up Screen
  return (
    <AuthStack.Navigator>
      <Stack.Screen
        name="Login"
        component={LoginScreen}
        options={{
          headerShown: false
        }}
      />
    </AuthStack.Navigator>
  );
};

export default function Navigation({ colorScheme, navigation }) {
  return (
    <NavigationContainer ref={navigationRef}
      linking={LinkingConfiguration} theme={colorScheme === 'light' ? DarkTheme : DefaultTheme}>
      <RootNavigator navigation={navigation} />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */

function RootNavigator({ navigation }) {
  return (
    <Stack.Navigator initialRouteName='Splash'>
      <Stack.Screen name='Splash' component={SplashScreen} options={{ headerShown: false }} />
      <Stack.Screen name="Auth" component={Auth} options={{ headerShown: false }} />
      <Stack.Screen name="Root" component={BottomTabNavigator} options={{ headerShown: false }} />
      <Stack.Screen name="Groups" component={GroupScreen} />
      <Stack.Screen name="AddGroup" component={AddGroupScreen} options={{ headerTitle: 'Add Group' }} />
      <Stack.Screen name="EditGroup" component={EditGroupScreen} options={{ headerTitle: 'Edit Group' }} />
      <Stack.Screen name="GroupDetail" component={GroupDetailScreen} options={{ headerTitleStyle: { color: 'white' } }} />
      <Stack.Screen name="Tasks" component={TasksScreen} />
      <Stack.Screen name="AddTask" component={AddTaskScreen} options={{ headerTitle: 'Add Task' }} />
      <Stack.Screen name="EditTask" component={EditTaskScreen} options={{ headerTitle: 'Edit Task' }} />
      <Stack.Screen name="TaskDetail" component={GroupDetailScreen} options={{ headerTitleStyle: { color: 'white' } }} />
      <Stack.Screen name="AddLocation" component={AddLocationScreen} options={{ headerTitle: 'Add Location' }} />
      <Stack.Screen name="AddEvent" component={AddEventScreen} options={{ headerTitle: 'Add Event' }} />
      <Stack.Screen name="JournalSettings" component={JournalSettingsScreen} options={{ headerTitle: 'Journal Settings' }} />
      <Stack.Screen name="JournalEntry" component={JournalEntryScreen} options={{ headerTitle: 'Journal Entry' }} />
      <Stack.Screen name="JournalUpdate" component={JournalUpdateScreen} options={{ headerTitle: 'Journal Update' }} />
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator({ navigation }) {
  const colorScheme = useColorScheme();
  const { userInfo } = React.useContext(UserInfoContext);
  return (
    <BottomTab.Navigator
      initialRouteName="Groups"
      screenOptions={{
        tabBarActiveTintColor: Colors.seaBlueColor,
        tabBarShowLabel: false,
        headerTitleStyle: { color: Colors.seaBlueColor },
        headerRight: (props) => GlobalDefaultHeader.GlobalHeaderRight({ userInfo, navigation }),
        //headerLeft: (props) => GlobalDefaultHeader.GlobalHeaderLeft({ userInfo, navigation, props })
      }}>
        
        {/* <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: 'Life Oar Home',
          tabBarLabel: 'Home',
          tabBarIcon: ({ color }) => <TabBarIcon name="home" color={color} />,
          tabBarShowLabel: true,
        }}
      /> */}
      <BottomTab.Screen
        name="Journal"
        component={JournalScreen}
        options={{
          title: 'Life Oar Journal',
          tabBarLabel: 'Journal',
          tabBarIcon: ({ color }) => <TabBarIcon name="pencil" color={color} />,
          tabBarShowLabel: true,
        }}
      />

      <BottomTab.Screen
        name="Tasks"
        component={TasksScreen}
        options={{
          title: 'Life Oar Tasks',
          tabBarLabel: 'Tasks',
          tabBarIcon: ({ color }) => <TabBarIcon name="list-ol" color={color} />,
          tabBarShowLabel: true,
        }}
      />
      <BottomTab.Screen
        name="Schedules"
        component={ScheduleScreen}
        options={{
          title: 'Life Oar Schedules',
          tabBarLabel: 'Schedules',
          tabBarIcon: ({ color }) => <TabBarIcon name="calendar-alt" color={color} />,
          tabBarShowLabel: true,
        }}
      />
      <BottomTab.Screen
        name="Groups"
        component={GroupScreen}
        options={{
          title: 'Life Oar Groups',
          tabBarLabel: 'Groups',
          tabBarIcon: ({ color }) => <TabBarIcon name="users" color={color} />,
          tabBarShowLabel: true,
        }}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome6>['name'];
  color: string;
}) {
  return <FontAwesome6 size={30} style={{ marginBottom: -3 }} {...props} />;
}
