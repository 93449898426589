import React, { useRef, useState } from "react";
import { seaBlueColor } from "../../constants/Colors";
import { Modal, TouchableOpacity } from "react-native";
import {
  StyleSheet,
  Text,
  View
} from 'react-native';
import { Input, Icon } from '@rneui/themed';

import CalendarPicker from 'react-native-calendar-picker';
import { globalStyles } from "../../constants/GlobalStyles";
import moment from "moment";

export default function DatePicker({ route, navigation, minDate, maxDate, isOpen, onDateChange }) {
  const [selectedStartDate, selectedEndDate] = useState('');
  const startDate = selectedStartDate ? selectedStartDate.toString() : '';
  const endDate = selectedEndDate ? selectedEndDate.toString() : '';

  return (
    <>
      <View style={[globalStyles.modalView, { display: isOpen ? "flex" : "none", zIndex: isOpen ? 0 : 1, maxWidth: 85 }]}>
        <Modal animationType="slide" visible={isOpen} transparent={true}>
          <View style={[globalStyles.container, { display: isOpen ? "flex" : "none", zIndex: isOpen ? 1 : 0 }]}>
            <CalendarPicker
              startFromMonday={true}
              scaleFactor={400}
              allowRangeSelection={false}
              minDate={minDate}
              maxDate={maxDate}
              todayBackgroundColor={seaBlueColor}
              selectedDayColor={seaBlueColor}
              selectedDayTextColor="#ffffff"
              onDateChange={onDateChange}
            />
          </View>
        </Modal>
      </View>
    </>
  );
}