/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../navigationTypes';
import { JournalEntry } from '../types/oarTypes';
import Journal from '../components/journal/Journal';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Root: {
        screens: {
          Home: {
            screens: {
              HomeScreen: 'home',
            },
          },
          Public: {
            path: 'public',
            screens: {
              PublicScreen: 'public',
            },
          },
          Journal: {
            path: 'journal',
            screens: {
              JournalScreen: 'journal',
              JournalSettingsScreen: 'journalSettings',
              JournalEntryScreen: 'journalEntry',
              JournalUpdateScreen: 'journalUpdate',
            }
          },
          Tasks: {
            path: 'tasks',
            screens: {
              TasksScreen: 'tasks',
              AddTaskScreen: 'addTask',
              EditTaskScreen: 'editTask'
            },
          },
          Locations: {
            path: 'locations',
            screens: {
              LocationsScreen: 'locations',
              AddLocationScreen: 'addLocation',
            },
          },
          Schedules: {
            path: 'schedules',
            screens: {
              SchedulesScreen: 'schedules',
            },
          },
          Groups: {
            path: 'groups',
            screens: {
              GroupScreen: 'groups',
              AddGroupScreen: 'addGroup',
              DeleteGroupScreen: 'deleteGroup',
              EditGroupScreen: 'editGroup',
              GroupDetailScreen: 'groupDetail'
            },
            
            },
          },
        },
        UserInfoModal: 'userInfo',
        Login: 'login',
        Splash: 'splash',
        NotFound: '*',
      },
    },
  };

  export default linking;