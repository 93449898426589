import { Platform, StyleSheet, Dimensions } from "react-native";
import { lightThemeColor } from '../components/journal/journalTheme';
import * as Colors from "./Colors";

export const globalStyles = StyleSheet.create({
	container: {
		flex: 1,
		width: '100%',
		backgroundColor: '#ffffff',
	},
	spinner: {
		flex: 1,
		width: '100%',
		backgroundColor: '#ffffff',
		justifyContent: 'center',
		alignContent: "center",
		alignItems: "center",
	},
	calendarContainer: {
		flex: 1,
		width: '100%',
		height: '95%',
		position: 'relative',
		justifyContent: 'center',
		alignContent: "center",
		backgroundColor: '#ffffff',
		borderColor: "lightgray",
		borderWidth: 1,
		borderRadius: 10,
	},
	calendarControl: {
		backgroundColor: '#fff',
		position: 'relative',
		flexGrow: 1,
	},
	dropDownContainer: {
		flex: 1,
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: '#fff',
		paddingLeft: 10,
		paddingRight: 10,

	},
	chatContainer: {
		flex: 1,
		width: '98%',
		borderColor: "lightgray",
		borderWidth: 1,
		borderRadius: 10,
		marginBottom: 10,
		backgroundColor: 'white',
	},
	chatMessageActionBarRight: {
		justifyContent: "flex-start",
		display: "flex",
		position: "relative",
		flexDirection: "row"
	},
	chatMessageActionBarLeft: {
		justifyContent: "flex-start",
		display: "flex",
		position: "relative",
		flexDirection: "row"

	},
	centeredContainer: {
		flex: 1,
		width: '100%',
		justifyContent: 'center',
		alignContent: "center",
		alignItems: "center",
		
		backgroundColor: 'white',
	},
	formContainer: {
		flex: 1,
		width: '95%',
		justifyContent: 'center',
		alignContent: "center",
		alignItems: "center",
		borderColor: "lightgray",
		borderWidth: 1,
		borderRadius: 10,
		backgroundColor: 'white',
	},

	listContainer: {
		minWidth: "90%",
		borderColor: "lightgray",
		borderWidth: 1,
		borderRadius: 10,
		minHeight: "auto",
		backgroundColor: 'white',

	},
	chatListContainer: {
		minWidth: "90%",
		borderColor: "lightgray",
		borderWidth: 1,
		borderRadius: 10,
		height: "100%",
		flexGrow: 1,
		backgroundColor: 'white',

	},
	chatItemContainer:
	{
		height: "100%",
		flexDirection: "row",
		flexWrap: "wrap",
		flexGrow: 0,
		alignItems: "center",
		paddingTop: 10,
		paddingBottom: 10,
		borderColor: "lightgray",
		borderWidth: 1,
		justifyContent: 'center',
		borderRadius: 10,

	},
	cardSubTitle: {
		fontSize: 12,
		paddingBottom: 0,
		paddingTop: 0,
		marginBottom: 0,
		marginTop: 0,
		flexGrow: 0
	},
	listItem: {
		backgroundColor: 'white',
		flex: 1,
		borderRadius: 5,
		padding: 5,
		marginRight: 10,
		marginTop: 5,
	},
	listItemText: {
		fontSize: 14,
		color: Colors.seaBlueColor,
		backgroundColor: 'white',
		
	},
	journalSection: {
		backgroundColor: lightThemeColor,
    color: Colors.seaBlueColor,
    textTransform: 'uppercase',
    fontSize: 16,
	},
	journalItemContainer: {
		padding: 20,
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderBottomColor: Colors.seaBlueColor,
    flexDirection: 'row',
		alignItems: "center",
	},

	journalItemTitle: {
		fontWeight: "bold",
		color: Colors.seaBlueColor,
		fontSize: 13,
		backgroundColor: 'white',
		minWidth: "15%"
	},
	journalItemText: {
		fontWeight: "bold",
		color: Colors.seaBlueColor,
		fontSize: 13,
		backgroundColor: 'white',
	},
	listItemTitle: {
		fontWeight: "bold",
		color: Colors.seaBlueColor,
		fontSize: 16,
		backgroundColor: 'white',
	},
	listItemContainer:
	{
		flexDirection: "row",
		alignItems: "center",
		borderColor: "lightgray",
		borderWidth: 1,
		borderRadius: 10,
		backgroundColor: 'white',
	},
	dropDown: {
		borderColor: "lightgray",
		width: "90%",
		borderWidth: 1,
		borderRadius: 10,
		alignItems: "center",
		justifyContent: "center",

	},
	headerButtonsContainer: {
		flex: 1,
		flexDirection: "row",
		alignItems: "flex-start",
		alignContent: "flex-start",
		justifyContent: "flex-start",
		minHeight: 30,
		backgroundColor: 'white',
	},
	textInput: {
		borderColor: "lightgray",
		borderWidth: 1,
		borderRadius: 10,
		color: "black",
		height: 50,
		paddingLeft: 10,

		fontSize: 14,

	},
	textInputContainerStyle: {
		borderBottomWidth: 0,
		paddingLeft: 0,
		paddingRight: 0,
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		fontWeight: "bold",

	},
	headerRightContainer: {
		flexDirection: "row",
		flexGrow: 0,
		alignContent: "flex-end",
		backgroundColor: 'white',
	},
	title: {
		fontWeight: "bold",
		fontSize: 18,
		marginLeft: 5,
		marginBottom: 3,
		marginTop: 3,
		color: Colors.seaBlueColor
	},
	cardTitle: {
		fontWeight: "bold",
		fontSize: 18,
	},
	calendarDate: {
		fontSize: 14,
		color: Colors.seaBlueColor
	},
	modalTitle: {
		fontWeight: "bold",
		fontSize: 14,
		marginBottom: 5,
		marginTop: 5,
		color: Colors.seaBlueColor
	},
	modalText: {
		fontSize: 16,
		marginBottom: 5,
		marginTop: 5
	},
	dayOfWeekAvatar: {

	},
	titleContainer: {
		height: 45,
		backgroundColor: 'lightgray',
		borderTopRightRadius: 10,
		borderTopLeftRadius: 10,
		paddingHorizontal: 20,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '75%',
	},
	image: {
		marginBottom: 40,
	},
	map: {
		height: "100%",
		width: "100%",
		flexBasis: '40%',
		flex: 1
	},
	inputView: {
		borderRadius: 30,
		width: "70%",
		height: 45,
		marginBottom: 20,
		alignItems: "center",
	},
	signup_button: {
		height: 30,
		marginBottom: 30,
	},
	createButton: {
		width: "70%",
		borderRadius: 10,
		height: 50,
		alignItems: "center",
		justifyContent: "center",
		marginTop: 40,
		backgroundColor: "#6200EE",
		marginBottom: 20,
		fontSize: 14,
	},
	createButtonText: {
		color: 'black',
		fontSize: 20,
		textAlign: 'center'
	},
	editBtn: {
		width: "70%",
		borderRadius: 25,
		height: 50,
		alignItems: "center",
		justifyContent: "center",
		marginTop: 40,
		backgroundColor: Colors.seaBlueColor,
		marginBottom: 20,
	},
	loginBtn: {
		width: "70%",
		borderRadius: 25,
		height: 50,
		alignItems: "center",
		justifyContent: "center",
		marginTop: 40,
		backgroundColor: Colors.seaBlueColor,
		marginBottom: 20,
	},
	loginText: {
		color: 'white',
	},
	switch: {
		alignSelf: 'flex-end',
		marginTop: 5,
	},
	picker: {
		width: '100%',
		opacity: 0,
	},
	saveBtn: {
		width: "70%",
		borderRadius: 25,
		height: 50,
		alignItems: "center",
		justifyContent: "center",
		marginTop: 40,
		backgroundColor: Colors.seaBlueColor,
		marginBottom: 20,
	},
	searchBtn: {
		width: "70%",
		borderRadius: 25,
		height: 50,
		alignItems: "center",
		justifyContent: "center",
		marginTop: 40,
		backgroundColor: Colors.seaBlueColor,
		marginBottom: 40,
		color: 'white',
	},
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: 22,
		backgroundColor: 'white',
	},
	modalView: {
		width: '75%',
		margin: 0,
		backgroundColor: 'white',
		borderBottomRightRadius: 10,
		borderBottomLeftRadius: 10,
		padding: 35,
		alignItems: 'center',
		alignContent: 'flex-start',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5,
	},
	groupCommandBarContainer: {
		flexDirection: "column",
		maxWidth: 30,
		height: "100%",
		borderStyle: "solid",
		borderBottomColor: "lightgrey",
		borderBottomWidth: 1,
		borderTopColor: "lightgrey",
		borderTopWidth: 1,
		alignItems: "center",
		justifyContent: "flex-start",

	},
	groupSideBarContainer: {

	},
	suggestionContainer: {
		maxHeight: 190,
		backgroundColor: 'rgba(0,0,0,0.08)',
		width: '80%'
	},
	commandBarContainer: {
		flexDirection: "row",
		width: "100%",
		flexGrow: 0,
		borderStyle: "solid",
		borderBottomColor: "lightgrey",
		borderBottomWidth: 1,
		borderTopColor: "lightgrey",
		borderTopWidth: 1,
		alignItems: "center",
		justifyContent: "center",

	},
	activityIndicator: {
		alignItems: 'center',

	},
	groupItem: {
		backgroundColor: 'white',
		flex: 1,
		borderRadius: 5,
		padding: 10,
		marginRight: 10,
		marginTop: 5,
	},
	groupItemText: {
		fontSize: 12,
		color: Colors.seaBlueColor,
	},
	groupItemTitle: {
		fontWeight: "bold",
		color: Colors.seaBlueColor,
	},
	agendaItem: {
		backgroundColor: 'white',
		flex: 1,
		borderRadius: 5,
		padding: 10,
		marginRight: 10,
		marginTop: 5,
	},
	agendaItemText: {
		fontSize: 14,
		color: Colors.seaBlueColor,

	},
	agendaItemTitle: {
		fontWeight: "bold",
		color: Colors.seaBlueColor,
		fontSize: 16,
	},
	agendaEmptyDate: {
		height: 15,
		flex: 1,
		paddingTop: 30
	},
	agendaCustomDay: {
		margin: 10,
		fontSize: 24,
		color: 'green'
	},
	agendaDayItem: {
		marginLeft: 34
	},
	modalContainer: {
		width: "85%",
		position: 'absolute',
		bottom: Dimensions.get('window').width / 2 + 120, // Give bottom as per your requirement here I have given with keyboard height and composer input height
		justifyContent: 'flex-end',
		alignSelf: 'center',
		margin: 0,
		...Platform.select({
			android: {
				marginBottom: 70
			},
			ios: {
				marginBottom: 95
			}
		})
	},
});
