import { FontAwesome5 } from '@expo/vector-icons';
import { Avatar } from "@rneui/base";
import moment from "moment";
import React from "react";
import { Pressable, SafeAreaView, Text, TouchableOpacity, View, VirtualizedList } from "react-native";
import { globalStyles } from "../../constants/GlobalStyles";
import { TaskPriority, TaskState } from "../../types/oarTypes";


export function TaskComponent({ navigation, route, listOfItems, onDelete, onEdit, taskType }: any) {
  let _taskType = taskType == null ? 0 : Number.parseInt(taskType)
  let groupId = _taskType == 1 ? route.params.groupId : null

  const getItem = (data: any[], index: number) => (
    {
      id: `${data[index]._id}`,
      title: `${data[index].title}`,
      priority: `${data[index].priority}`,
      state: `${data[index].state}`,
      dueDate: `${data[index].dueDate}`,
      groupId: `${data[index].groupId}`,
      groupName: `${data[index].groupName}`,
      assigned: data[index].assigned,
      assignedName: data[index].assignedName
    }
  );

  const onTaskPress = (taskId: string, taskGroupId?: string) => {
    if (taskGroupId != 'null') {
      navigation.navigate('EditTask', { 'taskId': `${taskId}`, 'groupId': taskGroupId })
    }
    else {
      navigation.navigate('EditTask', { 'taskId': `${taskId}` })
    }
  }

  const getListItemCount = (data: any[]) => {
    if (typeof data == "undefined") {
      return 0
    }
    else return data.length
  }

  function ListIcon(props: {
    name: React.ComponentProps<typeof FontAwesome5>['name'];
    color: string;
  }) {
    return <FontAwesome5 size={25} style={{}} {...props} />;
  }

  const findPriorityLabelByValue = (value: number) => {
    const priority = TaskPriority.find((item) => item.value == value);
    return priority ? priority.label : '';
  };

  const findStateLabelByValue = (value: number) => {
    const state = TaskState.find((item) => item.value == value);
    return state ? state.label : '';
  };

  const getDueDateLabel = (dueDate: any) => {
    if (dueDate !== 'null') {
      return `${Math.abs(daysUntilTaskDue(dueDate))} days ${daysUntilTaskDue(dueDate) < 0 ? "ago" : ""}`
    }
    else {
      return "No Due Date"
    }
  };

  const daysUntilTaskDue = (dueDate: any) => {
    const dateDue = moment.utc(dueDate);
    return dateDue.diff(moment.now(), "days") + 1
  };

  const Item = ({ item }) => (
    <View style={globalStyles.listItemContainer}>
      <View style={{ flexDirection: "row", width: '95%', flexWrap: "nowrap", paddingLeft: 15, flex: 4 }}>
        <View style={[globalStyles.container, { flexDirection: 'row', flexWrap: 'nowrap', paddingLeft: 10 }]}>
          <Avatar
            overlayContainerStyle={{ flexDirection: "row", paddingRight: 10, alignItems: "center" }}
            containerStyle={{ display: "flex", backgroundColor: item.priority == "0" ? "red" : item.priority == "1" ? "orange" : item.priority == "2" ? "yellow" : "green" }} rounded
            size={30}
            title={(Number.parseInt(item.priority) + 1).toString()}
            titleStyle={{ color: "black", fontWeight: "bold" }} />
          <View style={{ flexDirection: "row", flex: 1, alignItems: "flex-start" }}>
            <View style={[globalStyles.container, { alignItems: "flex-start", paddingLeft: 5 }]}>
              <TouchableOpacity style={[globalStyles.listItem, { flex: 1 }]} onPress={() => onTaskPress(item.id, item.groupId)}>
                <Text style={globalStyles.listItemTitle}>{item.title}</Text>
                <Text style={globalStyles.listItemText}>{findPriorityLabelByValue(Number.parseInt(item.priority))}</Text>
                <Text style={globalStyles.listItemText}>{findStateLabelByValue(Number.parseInt(item.state))} </Text>
                <Text style={globalStyles.listItemText}>Due in: {getDueDateLabel(item.dueDate)}</Text>
                {item.groupId != 'null' && taskType == 0 &&
                  <>
                    <Text style={globalStyles.listItemText}>Group: {item.groupName}</Text>
                  </>
                }
                {item.groupId != 'null' && taskType == 1 &&
                  <Text style={globalStyles.listItemText}>Assigned To: {item.assignedName}</Text>
                }
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: "row-reverse", alignItems: "center", paddingRight: 10 }}>
        <Pressable onPress={() => onDelete(item.id, groupId)} >
          <ListIcon name="times-circle" color="black"></ListIcon>
        </Pressable>
        <Pressable style={{ marginRight: 10 }} onPress={() => onTaskPress(item.id, item.groupId)}>
          <ListIcon name="edit" color="black"></ListIcon>
        </Pressable>
      </View>
    </View>
  );
  return (
    <>
      {listOfItems.length == 0 &&
        <Text style={globalStyles.agendaItemTitle}>You have no open Tasks. Use the plus sign in the upper left to get started.</Text>
      }
      {listOfItems.length > 0 &&
        <SafeAreaView style={{ minWidth: "95%", flex: 1 }}>
          <VirtualizedList style={globalStyles.listContainer}
            data={listOfItems}
            initialNumToRender={30}
            renderItem={({ item }) => <Item item={item} />}
            keyExtractor={item => item.id}
            getItemCount={getListItemCount}
            getItem={getItem}
          />
        </SafeAreaView>
      }
    </>
  );
}

